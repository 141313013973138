"use client";

import { endOfMonth, format, set, startOfMonth } from "date-fns";
import React from "react";
import { cn } from "tailwind-config";

import { Calendar as CalendarIcon } from "../../Icons";
import { Calendar } from "../Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";

export interface DatePickerProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "value" | "children"> {
  value?: Date;
  onValueChange: (value: Date | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  minDate?: Date;
  maxDate?: Date;
  compact?: boolean;
}

const formatPatternLong = "MMMM d, yyyy";
const formatPatternShort = "MM/dd/yy";

export const DatePicker = React.forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    {
      value,
      onValueChange,
      compact = false,
      placeholder = compact ? formatPatternShort : formatPatternLong,
      disabled,
      invalid,
      minDate,
      maxDate,
      className,
      ...props
    },
    ref,
  ) => (
    <Popover modal>
      <PopoverTrigger
        ref={ref}
        className={cn(
          "inline-flex w-full items-center justify-start rounded border-2 px-3 py-2 text-left text-large font-regular leading-5 outline-none focus:border-brand-purple data-[state=open]:border-brand-purple",
          disabled && "bg-lightBlue [&>svg]:text-gray",
          (!invalid || disabled) && "border-mediumGray",
          invalid && !disabled && "border-error",
          !value && "text-gray",
          className,
        )}
        disabled={disabled}
        {...props}
      >
        <span className="truncate">
          {value ? format(value, compact ? formatPatternShort : formatPatternLong) : placeholder}
        </span>
        <CalendarIcon className="ml-auto size-4 flex-shrink-0 text-brand-purple" />
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={value ?? undefined}
          onSelect={(newValue) => {
            if (!value || !newValue) return onValueChange(newValue);
            onValueChange(
              set(newValue, {
                hours: value.getHours(),
                minutes: value.getMinutes(),
                seconds: value.getSeconds(),
                milliseconds: value.getMilliseconds(),
              }),
            );
          }}
          autoFocus
          defaultMonth={value}
          startMonth={minDate && startOfMonth(minDate)}
          endMonth={maxDate && endOfMonth(maxDate)}
          disabled={[...(minDate ? [{ before: minDate }] : []), ...(maxDate ? [{ after: maxDate }] : [])]}
        />
      </PopoverContent>
    </Popover>
  ),
);

DatePicker.displayName = "DatePicker";
